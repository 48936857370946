<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="newsletter"></submenu-account>
      
    <div class="submenu-content">
      <h2 class="pb-4">Email Newsletter</h2>
      
      <div v-if="is_customer" class="pb-4">
        <div><strong class="text-dark">Customer ID: </strong> {{custno}}</div>
        <div><strong class="text-dark">Legal Business Name: </strong> {{user_name}}</div>
        <div><strong class="text-dark">Email: </strong> {{user_email}}</div>
      </div> 
      <div v-else class="pb-4">
        <div>User ID : {{custno}}</div>
        <div>Email : {{user_email}}</div>
      </div>

      <form v-if="is_customer" @submit.prevent="handleSubmit">
        <div v-if="error">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            {{error}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div v-if="message">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            {{message}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        
        
        <p class="h5 mt-4">Email Address: {{user_email}}
          <span class="badge bg-success" v-if="eblast_status == true">Subscribed</span>
        </p>
        <div class="mb-4 form-check">
          <div v-if="eblast_status == true">
            <input type="checkbox" class="form-check-input" v-model="eblast">
            <label class="form-check-label ps-2">Unsubscribe Email Newsletter</label>
            <div id="help" class="mb-5 ps-2 form-text col-md-7">
              I would like to unsubscribe from Ma Labs email newsletters.<br/>
* You will still receive emails for your orders such as order confirmation, shipment, etc.
            </div>
          </div>
          <div v-else>
            <input type="checkbox" class="form-check-input" v-model="eblast">
            <label class="form-check-label ps-2">Subscribe Email Newsletter</label>
            <div id="help" class="mb-5 ps-2 form-text col-md-7">
              I would like to subscribe to Ma Labs email newsletters in order to receive special promotions,
product updates, and new product information in my email inbox directly.
            </div>
          </div>
          
        </div>

        
        <div v-if="eblast_status != 'n/a'"> 
          <button type="submit" v-if="waiting == false" class="btn btn-primary">Submit</button>
          <button type="submit" v-if="waiting == true" class="btn btn-primary" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Submit            
          </button>
        </div>
      </form>

    </div> 
   
  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI } from '../../utils/axios-api'

export default {
  name: 'Newsletter',
  components: { submenuAccount, BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const message = ref("")
    const error = ref("")

    const store = useStore()
    const user = ref(store.getters.user)
    const is_customer = ref(store.getters.isCustomer)
    const user_name = ref(store.getters.userName)
    const custno = ref(store.getters.custno)
    const user_email = ref(store.getters.userEmail)
    
    
    //form data
    const eblast_status = ref("n/a")
    const eblast = ref("")
    const subscribe = ref("")

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Email Newsletter", link: "Newsletter"})
    
    if (is_customer) {
      getAuthAPI.get('/product/customer-service/status/' + custno.value + '/'+ user_email.value)
      .then(response => {
        eblast_status.value = response.data.eblast
      })
      .catch(err => {
          error.value = err.message
      })
    }

    const handleSubmit = () => {
      error.value = ""
      message.value = ""
      
      if (eblast.value == false) {
        error.value = "Please check the box and click submit button."
      }
      else {
        waiting.value = true
        
        var subscribe
        if (eblast_status.value == true) subscribe = "no"
        else subscribe = "yes"
        let payload = {"eblast": eblast.value,
                      "subscribe":subscribe,
                      "custno": custno.value,
                      "email": user_email.value}
        
        getAuthAPI.post('/account/customer-service/eblast/', payload)
        .then(response => {
          let res = response.data
          if (res["done"] == true) message.value = res["message"]
          else error.value = res["message"]
          
          waiting.value = false
          
          //reset eblast_status
          if (subscribe == "yes") eblast_status.value = true
          else eblast_status.value = false

          //clear eblast checkbox
          eblast.value = false

          //update subscribe status in store user and in local storage
          store.dispatch('updateSubscribeStatus', eblast_status.value)
        })
        .catch(err => {
            error.value = err
            waiting.value = false
        })

      }

    }
    return {waiting,
            message,
            error, 
            user, 
            is_customer,
            user_name, 
            custno, 
            user_email,
            eblast_status, 
            eblast, 
            handleSubmit,
            breadcrumb_links }

  }
}
</script>


<style scoped>
.container {min-height:600px;}
</style>